body{
  background-color: #121212;
  font-family: "Helvetica Neue", sans-serif;
  color: white;
  width: 100vw;
}

.link {
  text-decoration: none;
  color: #5da0c6;
}

/* Nav bar */

.header{
  width: 100%;
  max-height: 10vmin;
  position: sticky;
  top: 0;
  position: -webkit-sticky;
  display: grid;
  grid-template-columns: 15vw 70vw 15vw;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(18,18,18,0.7), rgba(18,18,18,0));
  padding-bottom: 1vmin;

}

@media only screen and (max-width: 900px) {
  .header {
    min-height: 15vmin;
  }
}

.name-text {
  padding-left: 3vw;
}

.top-link {
  text-decoration: none !important;
  color: #ff98a8;
  text-shadow: 0.07vmin 0.07vmin #014753;
}

.header-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.navbar-svg {
  width: 3vw;
  height: 3vw;
  padding: 0.5vw;
}

.App-link {
  color: #fff;
  padding-right: 2vw;
  text-decoration: none !important;
}

svg {
  max-height: 5vmin;
}

h2 {
  color: #014753;
}

/*
.img-container {
  position: absolute;
  height: 100vh;
  text-align: center;
  z-index: -1;
  top: 0;
}*/

/* Intro */

.intro {
  min-height: 67vmin;
  padding-top: 22.5vh;
  padding-bottom: 0;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 900px) {
  .intro {
    min-height:70vh;
  }
}

.profile-img {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  opacity: 0.6;
  top: 0;
  z-index: -1;
  position: absolute;
  display: inline-block;
  overflow: hidden; 
  object-fit: cover;
}

/*media query - change size of picture in smaller browser - zoom dont squash*/



.intro-text {
  margin-top: 10vmin;
  text-align: center;
  font-weight: bold;
  color: #fff;
  text-shadow: 0.3vmin 0.3vmin rgba(93, 160, 198, 1);
  margin-right: 5vmin;
  margin-left: 5vmin;
}

@media only screen and (max-width: 900px) {
  .intro-text {
    margin-top: 25vmin;
  }
}


#projects {
  min-height: 50vh;
  color: #014753;
  background-color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 15vh 10vmin 15vh 10vmin;
}

.project-cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

@media only screen and (max-width: 900px) {
  .project-cards {
  flex-direction: column;
  }
}

.project-card {
  border-radius: 5px;
  box-shadow: 5px 5px 5px #999999;
  min-width: 40vw;
  padding-bottom: 0.5vh;
  margin: 4vmin 4vmin 3vmin 4vmin;
}

.project-img {
  border-radius: 5px;
  width: 100%;
  height: 40vh;
}

.project-link {
  text-decoration: none !important;
  color: #121212;
}


#languages {
  width: 100%;
  min-height: 50vh;
  text-align: left;
  background-color: #fff;
  padding-bottom: 1vmin;
  padding-top: 11vmin;
}

.comp-lang {
  background-color: #5da0c6;
  min-height: 40vmin;
  width: 60vw;
  margin-bottom: 5vmin;
  border-radius: 5px;
  box-shadow: 4px 4px 4px #c9c8c8;
  transition: width 1s;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.comp-lang-hover {
  background-color: #5da0c6;
  min-height: 40vmin;
  width: 95vw;
  z-index: 999;
  transition: width 1s;
  margin-bottom: 5vmin;
  border-radius: 5px;
  box-shadow: 4px 4px 4px #c9c8c8;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.laptop {
  min-width: 22vmin;
  min-height: 22vmin;
  padding: 0 7vmin 0 8vmin;
}

@media only screen and (max-width: 900px) {
  .laptop {
    display: none;
  }
}

.comp-list {
  list-style: none;
  font-weight: bold;
}

.comp-text {
  font-size: 1vmin;
  max-width: 25vmin;
  opacity: 0;
  transition: opacity 0s;
  padding-left: 0;
  padding-right: 0;
  margin-right: 1vw;
}

.comp-text-hover {
  max-width: 40vmin;
  text-align: left;
  opacity: 1;
  transition: opacity 2s;
}

li {
  padding-top: 1vmin;
}

.human-lang {
  background-color: #28729c;
  min-height: 40vmin;
  width: 60vw;
  margin-left: auto; 
  margin-right: 0;
  border-radius: 5px;
  box-shadow: -4px 4px 4px #c9c8c8;
  transition: width 1s;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.human-lang-hover {
  background-color: #28729c;
  min-height: 40vmin;
  width: 95vw;
  z-index: 999;
  margin-left: auto; 
  margin-right: 0;
  transition: width 1s;
  border-radius: 5px;
  box-shadow: -4px 4px 4px #c9c8c8;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.world-langs {
  min-width: 20vmin;
  min-height: 20vmin;
}

@media only screen and (max-width: 900px) {
  .world-langs {
    display: none;
  }
}

.lang-list {
  list-style: none;
  font-weight: bold;
}

.lang-text {
  font-size: 1vmin;
  max-width: 10vmin;
  opacity: 0;
  transition: opacity 0s;
  padding-right: -10vmin;
  margin-left: 1vw;
}

.lang-text-hover {
  max-width: 50vmin;
  text-align: center;
  opacity: 1;
  transition: opacity 2s;
}

#cards {
  min-height: 50vh;
  color: #121212;
  background-color: #fff;
  text-align: center;
  padding: 15vh 10vmin 10vmin 10vmin;
}

.card-container {
  padding-top: 7vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.value-card {
  box-shadow: 0px 0px 0px 0.5vmin rgba(93, 160, 198, 0.75); 
  border-radius: 5px;
  text-align: center;
  padding: 3vmin 2vmin 2vmin 2vmin;
  width: 20vmin;
  color: #fff;
  text-shadow: 0.1vmin 0.1vmin rgba(93, 160, 198, 1);
  background-color: rgba(93, 160, 198, 0.7);
  margin: 2vmin;
}

@media only screen and (max-width: 900px) {
  .value-card {
    width: 30vmin;
  }
}

.value-icon {
  height: 10vmin;
  padding-bottom: -3vmin;
}

#email {
  background-color: white;
  min-height: 10vh;
  display: flex;
  justify-content: center;
}

.email {
  background-color: #ffc5ce;
  color: #014753;
  text-align: center;
  width: 100%;
  border-radius: 5px;
  padding: 5vh;
}

.email-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.email-link {
  text-decoration: none;
  font-weight: bold;
  color: #5da0c6;
}

.email-icon {
  height: 6vmin;
  padding-right: 2vmin;
}

.footer {
  font-size: 1.5vmin;
  background-color: #fff;
  color: #121212;
  min-height: 10vh;
  padding-top: 10vh;
  text-align: center;
}


/*.rotated-text {

  -moz-box-shadow: inset 10px 10px 50px #fff;
  -webkit-box-shadow: inset 10px 10px 50px #fff;
  box-shadow: inset 10px 10px 50px #fff;


  color: #f65858;
  
  font-size: 5.4vw;
  transform: rotate(90deg);
  position: absolute;
  top: 1vmin;
  right: 1vmin;
  margin-right: -20vw;
  margin-top: 40vh;
}

.code-img {
  width: 100%;
  z-index: 99;
  transition: 1500ms opacity ease-out;
  position: absolute;
}

.code-img-faded {
  width: 100%;
  transition: 1500ms opacity ease-out;
  opacity: 0;
  position: absolute;
}
  .code-thin {
  width: 100%;
  margin-bottom: -2vh;
  }
*/